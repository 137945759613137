
import { query } from "vue-gtag";
import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "ReservationConfirmation",
})
export default class extends Vue {
  mounted() {
    query("event", "conversion", {
      'send_to': "AW-399680930/S5cvCPyV0YgDEKLLyr4B",
      'transaction_id': "",
    });
  }
}
